import (
	/* webpackChunkName: "ui" */
	/* webpackMode: "lazy" */
	'./ui.js').then(ui => ui.init());

if (process.env.NODE_ENV === 'production') {
	import (
		/* webpackChunkName: "sw-uninstall" */
		/* webpackMode: "lazy" */
		'./service-worker.js');

	import (
		/* webpackChunkName: "ga" */
		/* webpackMode: "lazy" */
		'./analytics.js').then(ga => ga.init());
}

if (document.getElementById('cse') != null) {
	import (
		/* webpackChunkName: "cse" */
		/* webpackMode: "lazy" */
		'./search.js').then(cse => cse.init());
}